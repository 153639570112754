<template>
  <GModal name="modal" class="modal modal-point-applied">
    <div slot="header">
      <div class="modal-title">
        <div class="text-red">Soco Point ditukarkan</div>
        <div class="text">
          <p>
            Yay, kamu mendapatkan <b>diskon</b>
            <br />
            <b>{{ (selectedPoint * 100) | currencyRp }}</b> dari tukar SOCO Point
          </p>
        </div>
      </div>
    </div>
    <div slot="body">
      <div class="confirm" @click="$emit('close')">
        <span>Oke</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalPointApplied',

  props: {
    selectedPoint: {
      type: Number,
      default: 0,
    },
  },
};
</script>
